<template>
  <div class="rate_list_box padding-md text-white">
    <div v-if="$route.path.lastIndexOf('/') === 0">
      <!-- 搜索框 -->
      <div class="box-card">
        <div class="text item">
          <el-form inline class="padding-top margin-sm-bottom">
            <el-form-item label="费率名称">
              <el-input
                v-model="searchForm.name"
                style="width: 250px"
                clearable
                placeholder="请输入费率名称"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                class="btn-purple"
                icon="el-icon-search"
                :disabled="role >= 1 ? true : false"
                @click="queryRate()"
                v-preventReClick
                >{{ $t("siteList.checkButton") }}</el-button
              >
              <el-button
                class="btn-green"
                icon="el-icon-plus"
                @click="onAdd(1)"
                v-auth="btnAuth.add"
                >{{ $t("deviceList.addEquipment") }}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 站点数据信息展示表格 -->
      <div class="table-box">
        <el-table
          :data="RateData"
          style="width: 100%"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
          :row-class-name="tableRowClassName"
          :row-style="{ height: '60px' }"
        >
          <el-table-column label="费率编号" prop="id" width="80">
          </el-table-column>
          <el-table-column label="费率名称" prop="name"> </el-table-column>
          <el-table-column label="详细说明" prop="note"> </el-table-column>
          <el-table-column label="创建时间" prop="createTime">
          </el-table-column>
          <el-table-column label="更新时间" prop="updateTime">
          </el-table-column>
          <el-table-column :label="$t('deviceList.operation')" width="180">
            <!-- 操作按钮 -->
            <template v-slot="{ row }">
              <div class="flex items-middle space-around">
                <el-button
                  type="text"
                  @click="onAdd(3, row)"
                  v-auth="btnAuth.view"
                  size="mini"
                >
                  <icon
                    type="icon-chakan1-copy-copy"
                    color="#234659"
                    size="28"
                  ></icon
                ></el-button>
                <el-button
                  class="text-red"
                  type="text"
                  @click="onDelete(row)"
                  v-auth="btnAuth.delete"
                  size="mini"
                >
                  <icon type="icon-shanchu1-F" color="#234659" size="25"></icon
                ></el-button>
                <el-button
                  class="text-red"
                  type="text"
                  @click="onAdd(2, row)"
                  v-auth="btnAuth.edit"
                  size="mini"
                >
                  <icon
                    type="icon-wendang-caogao-F"
                    color="#234659"
                    size="25"
                  ></icon
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 翻页 -->
        <div class="block" style="margin-top: 15px">
          <pagination
            :total="countNum"
            v-on:getCurrent="changeCurrent"
            v-on:getPageSize="changePage"
          ></pagination>
        </div>
      </div>
    </div>
    <rate-update ref="rateUpdate" @refresh="getRate"></rate-update>
  </div>
</template>

<script>
import pagination from "../../components/pagination.vue";
import { rateQuery, rateDel } from "../../api/rateList";
import i18n from "@/lang";
import RateUpdate from "../../components/rateUpdate.vue";
export default {
  components: { pagination, RateUpdate },
  data() {
    return {
      searchForm: {
        name: "",
      },
      RateData: [],
      searchRules: {
        siteId: [
          { required: false, message: "请输入站点ID", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
            message: "用户ID以字母开头，且只能包含英文字母、数字、下划线",
          },
        ],
      },
      object: {
        pageNumber: 1,
        pageSize: 10,
      },
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页的数据条数
      role: 0, //角色
      countNum: 0,
      btnAuth: {
        add: "rateAdd",
        delete: "rateDelete",
        edit: "rateEdit",
        view: "rateDetail",
      },
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getRate();
    },
    getRate() {
      // console.log(obj);
      rateQuery(this.object)
        .then((res) => {
          console.log(res.data.data.list);
          this.countNum = res.data.data.countNum;
          this.RateData = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onAdd(type, row = {}) {
      this.$refs.rateUpdate.open(type, row);
    },
    onDelete(row) {
      console.log(row.id);
      this.$confirm(
        i18n.t("prompt.deletePromptInfo"),
        i18n.t("prompt.promptInfo"),
        {
          confirmButtonText: i18n.t("prompt.confirm"),
          cancelButtonText: i18n.t("prompt.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          rateDel({ id: row.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: i18n.t("prompt.operateSuccessfully"),
              });
              this.getRate();
            } else {
              this.$message({
                type: "error",
                message: i18n.t("prompt.operationFailure") + res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: i18n.t("prompt.OperationCancelled"),
          });
        });
    },
    queryRate() {
      let obj = {
        pageNumber: this.object.pageNumber,
        pageSize: this.object.pageSize,
        para: {
          name: this.searchForm.name,
        },
      };
      rateQuery(obj).then((res) => {
        if (res.data.data.list.length == 0) {
          this.$message({
            type: "warning",
            message: "暂无结果",
          });
        } else {
          this.countNum = res.data.data.countNum;
          this.RateData = res.data.data.list;
        }
      });
    },
    toDevice(id) {
      console.log(id);
      this.$router.push({
        name: "DeviceList",
        query: {
          id: id,
        },
      });
    },
    changeCurrent(data) {
      this.object.pageNumber = data;
      this.currentPage = data;
      console.log(this.object);
      this.getRate();
    },
    changePage(data) {
      this.object.pageSize = data;
      this.pageSize = data;
      console.log(this.object);
      this.getRate();
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "warning-row";
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.rate_list_box{
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 130px);
    overflow: auto;
}
.table-box{
  padding: 20px;
    // background: linear-gradient(45deg, #35478a, #162257);
    background: #102135
    border-radius: 10px;
}
.table-add{
  margin-bottom: 20px
}
/deep/.el-table, .el-table tr, .el-table td, .el-table th {
    background-color: transparent!important;
}
</style>
